import React, { useEffect, useRef, useState } from 'react'
import img1 from 'assets/homeSvg/sec4-img1.png'
import img2 from 'assets/homeSvg/sec4-img2.png'
import img3 from 'assets/homeSvg/sec4-img3.png'
import img4 from 'assets/homeSvg/sec4-img4.png'
import styles from './sec4.module.css'
import Modal from './Modal'
import ScrollLock from 'react-scrolllock'

function SectionFour() {
  const [modal, setModal] = useState(false)
  const ref = useRef()
  const [tempdata, setTempdata] = useState([])

  const getData = (img, name, desc, linkedin) => {
    let tempData = [img, name, desc, linkedin]
    setTempdata(item => [1, ...tempData])
    return setModal(true)
  }
  //외부 클릭시 메뉴 종료
  const handleClickOutside = ({ target }) => {
    if (modal && !ref.current?.contains(target)) {
      //setModal(false)
    }
  }

  useEffect(() => {
    window.addEventListener('click', handleClickOutside)
    return () => {
      window.removeEventListener('click', handleClickOutside)
    }
  })
  //

  return (
    <>
      {modal && <ScrollLock />}
      <section className={styles.wrapper}>
        <section className={styles.container}>
          <h1 className={styles.title}>Management Team</h1>
          <p className={styles.subTitle}>
            The team of researchers and genome experts supporting the program{' '}
            <br /> is based in Singapore, Canada and China, and include two
            Nobel <br />
            prize winners.This makes LBC the first of its kind
            <br /> to be supported by Nobel Prize laureates&#46;
          </p>

          <div className={styles.tab_wrapper}>
            <ul className={styles.tab_container}>
              {tabBox.componentBox.map((item, index) => {
                return (
                  <button
                    data-aos="fade-up"
                    data-aos-delay={item.delay}
                    key={index}
                    className={styles.tabBox}
                    onClick={() => getData(item.img, item.name, item.desc, item.linkedin)}
                  >
                    <img src={item.img} alt="" className={styles.img} />
                    <p className={styles.p}>{item.name}</p>
                  </button>
                )
              })}
            </ul>
          </div>
        </section>
        {modal === true ? (
          <Modal
            img={tempdata[1]}
            name={tempdata[2]}
            desc={tempdata[3]}
	    linkedin={tempdata[4]}
            hide={() => setModal(false)}
          />
        ) : (
          ''
        )}
      </section>
    </>
  )
}
export default SectionFour

//박스데이터
const tabBox = {
  componentBox: [
    {
      id: 1,
      img: img1,
      name: 'Randy \n Schekman',
      desc: 'The Nobel Prize in Physiology or Medicine 2013. Cell biologist at the University of California, Berkeley.',
      linkedin: 'https://www.linkedin.com/in/randy-schekman-7713473/',
    },
    {
      id: 2,
      img: img2,
      name: 'Sabeeha \n Merchant',
      desc: 'Professor of Plant Biology at the University of California, Berkeley Member of the National Academy of Sciences (NAS) in 2012.',
      linkedin: 'https://www.linkedin.com/in/sabeeha-merchant-ba7aa55/',
      delay: 200,
    },
    {
      id: 3,
      img: img3,
      name: 'Eric \n Maskin',
      desc: 'The Nobel Prize in Physiology or Medicine 2013. Cell biologist at the University of California, Berkeley.',
      linkedin: '',
      delay: 400,
    },
    {
      id: 4,
      img: img4,
      name: 'Ryan \n Chris',
      desc: 'Currently serving as CEO at LBC （Previously employed at NovartisI have been serving as the R&D Director of the New Drug Development Department for six years in the company,）',
      linkedin: 'https://www.linkedin.com/in/ryanchris3/',
      delay: 600,
    },
  ],
}
