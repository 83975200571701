import React, { useEffect } from 'react'
import styles from './cell.module.css'
import img1 from 'assets/cell/img1.png'
import img2 from 'assets/cell/img2.png'
import img4 from 'assets/cell/img4.png'
import { docData } from './docData'
import Aos from 'aos'
import 'aos/dist/aos.css'
import { StickyContainer } from 'react-sticky'
import ScrollIntoView from 'react-scroll-into-view'

function Cell() {
  useEffect(() => {
    Aos.init({
      duration: 600,
      easing: 'ease-in-out',
      disable: function () {
        var maxWidth = 580
        return window.innerWidth > maxWidth
      },
    })
  }, [])

  return (
    <>
      <header className={styles.header_wrapper}>
        <header className={styles.header_container}>
          <div className={styles.header_layer}>
            <div className={styles.titleBox}>
              <h1 className={styles.title}>Cell Therapy</h1>
              <p className={styles.subTitle}>
                Cell therapy utilizes living cells from the patient or a donor
                to <br /> replace damaged or diseased cells or to stimulate the
                <br />
                body’s immune response or regeneration.
              </p>
            </div>
          </div>
        </header>
      </header>

      <section className={styles.section_wapper}>
        <section className={styles.section_boxs}>
          <StickyContainer>
            {/* 사이드메뉴 */}
            <ul className={styles.side_wrap}>
              {docData.sideLi.map((item, index) => {
                return (
                  <ScrollIntoView selector={item.id}>
                    <li
                      className={styles.sideMenu_box}
                      key={index}
                      id={item.id}
                    >
                      <div className={styles.sideMenu}>{item.title}</div>
                    </li>
                  </ScrollIntoView>
                )
              })}
            </ul>
          </StickyContainer>
          {/* == */}
          <section className={styles.section_container}>
            <div className={styles.section_layer}>
              {/* 섹션1 */}
              <div
                id="sec1"
                className={`${styles.sec_wrap} ${styles.secBox1}`}
                data-aos="fade"
              >
                <div className={styles.left}>
                  <h1 className={styles.title_doc}>Saving Cord Blood</h1>
                  <p className={`${styles.doc} ${styles.doc_sec1}`}>
                    Cord blood, which is also called 'placental blood', is blood
                    that remains in the umbilical cord and placenta following
                    the birth of a baby and after the umbilical cord is cut
                    after delivery. Through the science of cord blood banking,
                    cord blood can help nurture life, long after a baby's birth
                    and provide a source of stem cells should the need ever
                    arise for a stem cell transplant. This is because cord blood
                    is a rich source of stem cells known as haematopoietic stem
                    cells (HSCs). These HSCs are primarily responsible for
                    replenishing blood and regenerating the immune system.
                  </p>
                </div>
                <div className={styles.sec1_imgBox}>
                  <img src={img1} alt="" className={styles.secImg} />
                </div>
              </div>

              {/* 섹션2 */}
              <div
                id="sec2"
                className={`${styles.sec_wrap} ${styles.secBox2}`}
                data-aos="fade"
              >
                <h1 className={`${styles.title_doc} ${styles.title_doc_sec2}`}>
                  Cord Blood stem cell transplants are uesd in the following
                  ways
                </h1>
                <ul className={styles.ul_sec2}>
                  {docData.sec2.map((item, index) => {
                    return (
                      <li className={styles.li_secBox2}>
                        <div className={styles.li_sec2}>
                          <img
                            src={item.img}
                            alt=""
                            className={styles.li_img}
                          />
                          <p className={styles.sec_li_p}>{item.doc}</p>
                        </div>
                      </li>
                    )
                  })}
                </ul>
              </div>

              {/* 섹션3 */}
              <div
                className={`${styles.sec_wrap} ${styles.secBox3}`}
                data-aos="fade"
                id="sec3"
              >
                <h1 className={styles.title_doc}>
                  Benefits of Saving Cord Blood
                </h1>
                <p className={`${styles.doc} ${styles.doc_sec3}`}>
                  There are many reasons why you should bank your baby's cord
                  blood stem cells.
                  <br />
                  <br /> <b>They include:</b>
                  <br /> A guaranteed match for autologous transplants (where
                  the donor and recipient are the same individual). Autologous
                  stem cell transplants refer to transplants where the donor and
                  recipient of the stem cells are the same individual. The cord
                  blood that you are storing for your baby today is a potential
                  medical resource for the future, and does not require
                  stringent matching as opposed to conventional bone marrow stem
                  cell transplants.
                  <br />
                  <br /> A Readily Available Supply of Stored Haematopoietic
                  Stem Cells. In the event of an already time-critical situation
                  where stem cells are required for a transplant, having your
                  child’s cord blood stored compares well to having to do a
                  national or international search which is both costly and
                  time-consuming. Lower risk of Graft vs. Host Disease (GvHD)
                  for autologous transplants. Graft vs. Host Disease refers to a
                  common complication where the transplanted tissue attacks the
                  patient's own tissue. This disease will usually occur in
                  transplants when the donor and recipient are different. Ease
                  of collection, which is pain-free and risk-free to both mother
                  and child. Cord blood collection is a relatively easy task,
                  which is handled by your own delivering doctor. This process
                  does not affect the birthing process in any way.
                </p>
                <div className={styles.sec2_imgBox}>
                  <img src={img2} alt="" className={styles.secImg} />
                </div>
              </div>

              {/* 섹션4 */}
              <div
                className={`${styles.sec_wrap} ${styles.secBox4}`}
                data-aos="fade"
                id="sec4"
              >
                <h1 className={styles.title_doc}>
                  Cellular-based therapy for regenerative medicine
                </h1>
                <p className={`${styles.doc} ${styles.doc_sec4}`}>
                  Cord blood, which is also called 'placental blood', is blood
                  that remains in the umbilical cord and placenta following the
                  birth of a baby and after the umbilical cord is cut after
                  delivery. Through the science of cord blood banking, cord
                  blood can help nurture life, long after a baby's birth and
                  provide a source of stem cells should the need ever arise for
                  a stem cell transplant. This is because cord blood is a rich
                  source of stem cells known as haematopoietic stem cells
                  (HSCs). These HSCs are primarily responsible for replenishing
                  blood and regenerating the immune system.
                </p>
              </div>

              {/* 섹션5 */}
              <div className={styles.sec_wrap} data-aos="fade" id="sec5">
                <div className={styles.secBox5} data-aos="fade" id="sec5">
                  <div className={styles.sec_container}>
                    <div className={styles.sec_layer}>
                      <h1
                        className={`${styles.title_doc} ${styles.title_doc_sec5}`}
                      >
                        Biorecover Repair <br /> Concentrate
                      </h1>
                      <p className={`${styles.doc} ${styles.doc_sec5}`}>
                        The concentrate helps to nourish and moisturize
                        extravagantly, to inhibit inflammatory molecules, and
                        help the dermis and epidermis to regenerate; It also
                        functions to lock in moisture, enhance skin's protective
                        power, promote collagen rebound, strengthen muscle
                        bottom and build a healthy barrier.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* 섹션6 */}
              <div
                className={`${styles.sec_wrap} ${styles.secBox6}`}
                data-aos="fade"
                id="sec6"
              >
                <h1 className={styles.title_doc}>Anti-Wrinkle Injecti</h1>
                <p className={`${styles.doc} ${styles.doc_sec6}`}>
                  The cosmetic form of botulinum, a toxin is a popular
                  injectable that temporarily reduces or eliminates facial fine
                  lines and wrinkles. Injections of botulinum toxin, block the
                  nerve signals to the muscle in which it was injected. Without
                  a signal, the muscle is not able to contract. The end result
                  is diminished unwanted facial wrinkles or appearance.
                </p>
              </div>

              {/* 섹션7 */}
              <div
                className={`${styles.sec_wrap} ${styles.secBox6}`}
                data-aos="fade"
                id="sec7"
              >
                <h1 className={styles.title_doc}>
                  Stem cell and immune cell advanced therapy
                </h1>
                <p className={`${styles.doc} ${styles.doc_sec6}`}>
                  Stem cells are undifferentiated, or “blank,” cells. They’re
                  capable of developing into cells that serve numerous functions
                  in different parts of the body. Most cells in the body are
                  differentiated cells. These cells can only serve a specific
                  purpose in a particular organ. Since stem cells have the
                  ability to turn into various other types of cells, scientists
                  believe that they can be useful for treating and understanding
                  diseases.
                </p>
                <br />
                <sapn className={`${styles.doc} ${styles.doc_sec6_1}`}>
                  According to the recent research (2), stem cells can be used
                  to:
                </sapn>

                {docData.sec6.map((item, index) => {
                  return (
                    <li className={styles.li_sec6} key={index}>
                      <div className={styles.list} />
                      <p>{item.doc}</p>
                    </li>
                  )
                })}
                <div className={styles.sec2_imgBox}>
                  <img src={img4} alt="" className={styles.secImg} />
                </div>
              </div>

              {/* 섹션8 */}
              <div
                className={`${styles.sec_wrap} ${styles.secBox8}`}
                data-aos="fade"
                id="sec8"
              >
                <h1 className={styles.title_doc}>Immune cell therapy</h1>
                <p className={`${styles.doc} ${styles.doc_sec8}`}>
                  Immune cell therapy is an extremely promising approach for the
                  treatment of many diseases with an immune component including
                  cancer, autoimmune disease, and chronic inflammation. In many
                  immune cell therapies, specific cell populations are isolated,
                  genetically modified, activated, and expanded to the large
                  numbers required for patient treatment. T cells, NK cells,
                  regulatory T cells (Treg cells), or dendritic cells can be
                  redirected to attack tumors or amplify a local immune
                  response. Genome engineering can introduce chimeric antigen
                  receptors (CAR), T cell receptors (TCR), or other proteins to
                  enhance the intended therapeutic activity. A growing number of
                  CAR T-cell therapies are being developed and tested in
                  clinical studies.
                </p>
                <br />
                <sapn className={`${styles.doc} ${styles.doc_sec6_1}`}>
                  There are currently a total four immune cell therapies that
                  are approved by the FDA for the treatment of cancer:
                </sapn>

                {docData.sec7.map((item, index) => {
                  return (
                    <li className={styles.li_sec6} key={index}>
                      <div className={styles.list} />
                      <p className={styles.li_doc}>{item.doc}</p>
                    </li>
                  )
                })}
              </div>
            </div>
          </section>
        </section>
      </section>
    </>
  )
}

export default Cell
