import React from 'react'
import styles from './overview.module.css'
import headerImg from 'assets/overview/headerImg.svg'
import secImg from 'assets/overview/overview_img.png'
import { useMediaQuery } from 'react-responsive'

function Overview() {
  const mobile = useMediaQuery({ maxWidth: 1200 })
  return (
    <>
      <header className={styles.header_wrapper}>
        <header className={styles.header_container}>
          <div className={styles.header_layer}>
            <div className={styles.titleBox}>
              <h1 className={styles.title}>OverView</h1>
              <p className={styles.subTitle}>
                LBC has the solutions to advance your <br /> research to the
                clinic and beyond &#46;
              </p>
            </div>

            {mobile || (
              <div className={styles.h_imgBox}>
                <img src={headerImg} alt="" className={styles.headerImg} />
              </div>
            )}
          </div>
        </header>
      </header>

      <section className={styles.section}>
        <section className={styles.sec_wrap}>
          <p className={styles.sec_p}>
            <span className={styles.span}>Advancements</span> in stem cell
            therapy continue to shed light on how stem cells can be used in
            various clinical applications, which include cell replacement
            therapies, immunomodulatory therapies, and many others. Whether
            you’re developing an induced pluripotent stem cell therapy or adult
            stem cell therapy, LBC has the solutions to advance your research to
            the clinic and beyond.
            <br />
            <br />
            <br />
            During cellular replication, if genes become mutated or duplicated,
            an opportunity for disease can be created. By using cells and genes
            as therapeutic agents, it is possible to deliver durable cures.
            Instead of more traditional approaches such as small molecules and
            biologics that treat symptoms, these "living drugs" can persist in
            the patient for many years or potentially a lifetime. "Cell and gene
            therapy" is a single approach to addressing disease, but the
            potential methods for delivering treatments differ. This complex
            technology is quickly evolving and facing multiple challenges;
            quality and safety are paramount, and time is precious. Cell and
            gene therapies offer hope for many, as they are enhancing and saving
            the lives of people who often have no other treatment option.
          </p>
        </section>

        <section className={styles.sec_wrap}>
          <div className={styles.sec_imgBox}>
            <img src={secImg} alt="" className={styles.secImg} />
          </div>
        </section>

        <section className={styles.sec_wrap}>
          <p className={styles.sec_p}>
            <span className={styles.span}>Our AI-augmented model</span>{' '}
            developed by our LBCTools empowered our bio-chemist team to evaluate
            millions of molecular structures, curate effective therapy in fewer
            cycles. It does not only greatly reduce the expenses for unnecessary
            attempts and trials. The automation frees the manpower to further
            the scientific research for more life-changing solutions of wellness
            issues. Hence, enable us to emerge as a pioneer of the Biotech
            industry with an effective solution provider.
          </p>
        </section>
      </section>
    </>
  )
}

export default Overview
