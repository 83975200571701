import React from 'react'
import styles from './genetic.module.css'
import img1 from 'assets/genetic/img1.png'
import img2 from 'assets/genetic/img2.png'
import img3 from 'assets/genetic/img3.png'

function GeneticTherapy() {
  return (
    <>
      <header className={styles.header_wrapper}>
        <header className={styles.header_container}>
          <div className={styles.header_layer}>
            <div className={styles.titleBox}>
              <h1 className={styles.title}>Genetic Therapy</h1>
              <p className={styles.subTitle}>
                Gene therapy treats diseases with genetic material to change or
                supplement <br /> a gene whose absence or abnormality is causing
                the disease. Treatments
                <br /> can happen outside the body or inside the body.
              </p>
            </div>
          </div>
        </header>
      </header>

      <section className={styles.section_wapper}>
        <section className={styles.section_container}>
          <section className={styles.section_layer}>
            {/* 섹션1 */}
            <span className={styles.title_doc}>
              Genetic testing - CTC early cancer screening
            </span>{' '}
            <br />
            <br />
            <p className={styles.doc}>
              circulating tumour cells (CTCs) have recently been identified as
              valuable biomarkers for diagnostic and prognostic evaluations, as
              well for monitoring therapeutic responses to treatments. CTCs are
              rare cells which may be present as one CTC surrounded by
              approximately 1 million white blood cells and 1 billion red blood
              cells per millilitre of peripheral blood. Despite the various
              challenges in CTC detection, considerable progress in detection
              methods have been documented in recent times, particularly for
              methodologies incorporating nanomaterial-based platforms and/or
              integrated microfluidics. Herein, we summarize the importance of
              CTCs as biological markers for tumour detection, highlighting
              their mechanism of cellular invasion.
            </p>
            <div className={styles.sec1_imgBox}>
              <img src={img1} alt="" className={styles.secImg} />
            </div>
            <p className={styles.doc}>
              The CellSearch Circulating Tumor Cell (CTC) test is a simple blood
              test that helps oncologists in assessing the prognosis of patients
              with metastatic breast, colorectal, or prostate cancer. The
              CellSearch test is the only FDA-approved test for CTC assessment.
              Each result of the CellSearch test performed is verified by both a
              cytotechnologist and a pathologist to ensure quality.
            </p>
            {/* 섹션2 */}
            <div className={styles.sec2Box}>
              <ul className={`${styles.doc} ${styles.doc_sec2}`}>
                <span className={styles.title_doc}>
                  Test Results and
                  <br /> Consultations
                </span>
                <br />
                <li className={styles.li}>
                  &#8259; Each result provides a strong&#44; independent
                  predictor of survival
                </li>
                <li className={styles.li}>
                  &#8259; Each report prepared includes patient results for all
                  previous CTC tests to aid oncologists with monitoring
                </li>
                <li className={styles.li}>
                  &#8259; The pathologists and scientists are always available
                  for consultation on matters of test selection, collection and
                  processing procedures, results interpretation, new test
                  development and methodologies
                </li>
              </ul>

              <div className={styles.sec2_imgBox}>
                <img src={img2} alt="" className={styles.secImg} />
              </div>
            </div>
            {/* 섹션3-1 */}
            <span className={styles.title2_doc}>DNA Comprehensive Test</span>
            <p className={styles.doc}>
              Studies show that 50% of people with a cancer-causing gene
              mutation who do not have a family history of cancer. However,
              having a specific mutation can greatly increase your risk of
              developing certain cancers. For example, certain mutations in the
              BRCA1 gene can increase a woman’s chance of developing breast
              cancer up to 81%. Discover your risk across 36 cancers, including
              breast, colorectal, stomach & prostate, so you may take the right
              actions early.
            </p>
            {/* 섹션3-2 */}
            <span className={styles.title3_doc}>Common Diseases</span>
            <div className={styles.se3_imgBox}>
              <img src={img3} alt="" className={styles.secImg} />
            </div>
            <p className={`${styles.doc} ${styles.doc2}`}>
              More than 80% of the population is at risk of one chronic disease
              in their lifetime. However, many of them may be preventable,
              through early detection, treatments and lifestyle interventions.
              Discover your common health & disease risks across 75 conditions
              such as Stroke, Obesity, Diabetes, High Cholesterol, Hypertension
              & more, and take control of your health with our simple toolkit
              solution.
            </p>
          </section>
        </section>
      </section>
    </>
  )
}

export default GeneticTherapy
