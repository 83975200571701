import React from 'react'
import { Link } from 'react-router-dom'
import styles from './sec1.module.css'
import svg from 'assets/homeSvg/section1.svg'
import ScrollIntoView from 'react-scroll-into-view'

function SectionOne() {
  return (
    <section className={styles.wrapper}>
      <section className={styles.container}>
        <h1 className={styles.title}>
          Storage of{' '}
          <span style={{ color: '#3E4CFF' }}>
            genetic
            <br /> data
          </span>{' '}
          using blockchain
        </h1>
        <p className={styles.subTitle}>
          LifeBank Chain is a leading clinic-stage biotechnology company
          <br /> seeking to improve lives through the curative potential of gene
          <br /> and cell therapy.
        </p>

        <div className={styles.btnBox}>
          <a href="https://bit.ly/4ePXxCe" className={styles.btn1}>
            Get WhitePaper
          </a>
          <ScrollIntoView selector="#section2">
            <button className={styles.btn2}>Learn more &#62;</button>
          </ScrollIntoView>
        </div>

        <div className={styles.svgBox}>
          <img src={svg} alt="" className={styles.svg} />
        </div>
      </section>
    </section>
  )
}

export default SectionOne
