export const docData = {
  no1: [
    {
      li: 'Collaborates with Business partners to elicit and analyze requirements, and models business information and processes into workflows and use cases.',
    },
    {
      li: 'With Business partners, evaluates and selects suitable solutions within the parameters of IT infrastructures, compliance, and technical requirements.',
    },
    {
      li: 'Manages vendor implementation and configuration to address requirements and to ensure on-time and on-budget delivery.',
    },
    {
      li: 'Supports testing, validation, and documentation as necessary.',
    },
    {
      li: 'Provide system configuration and other on-going support post-implementation.',
    },
    {
      li: 'Provides training and support of systems as appropriate.',
    },
    {
      li: 'Assists with other systems and IT roles and functions as appropriate.',
    },
  ],

  no2: [
    {
      li: 'B.S. in a STEM field, Business Administration, Business Management, Information Systems, or related field',
    },
    {
      li: '5 plus years of business analysis, requirements gathering and working with clients to deploy IT solutions; 3+ years experience in biotech/pharma strongly preferred',
    },
    {
      li: 'Outstanding interpersonal, oral, and written communication skills.',
    },
    {
      li: 'Ability to collaborate with stakeholders in different business units to elicit, analyze, communicate, and to validate requirements to determine appropriate information systems.',
    },
    {
      li: 'Effective time management by prioritizing workload.',
    },
    {
      li: 'Excellent organizational skills.',
    },
    {
      li: 'Ability and willingness to learn new technology and tools to help meet user needs and become more effective.',
    },
    {
      li: 'Understanding of the life science industry and of systems for Laboratory, Manufacturing, Data Management & Analytics, and/or R&D is preferred.',
    },
  ],

  no3: [
    {
      li: 'Promote innovation and direct operational improvement and/or evaluation of new analytical techniques or technology.',
    },
    {
      li: 'Troubleshoot and resolve bioanalytical method issues to successful resolution.',
    },
    {
      li: 'Take accountability and demonstrate responsibility regarding scientific study conduct.',
    },
    {
      li: 'Ensure analytical development/validation activities conducted internally and with contracted services lead to robust methods that support bioanalytical analysis.',
    },
    {
      li: 'Conduct technology transfer between research organizations, in-house and contract laboratories.',
    },
    {
      li: 'Serve as the Responsible/lead Scientist for the bioanalytical portion of non-regulated as well as GLP and GCP studies.',
    },
    {
      li: 'Write and review study protocols, analytical procedure, reports for regulatory submissions.',
    },
    {
      li: 'Perform technical reviews of analytical data and be responsible for data interpretation, analysis, and reporting of results for method development and validation.',
    },
    {
      li: 'Provide scientific and technical input to the study team and other departments.',
    },
    {
      li: 'Ensure that any circumstances that may affect the quality and integrity of the study are identified and documented as they occur and that appropriate corrective actions are taken.',
    },
    {
      li: 'Identify process improvement opportunities, participate in execution of initiatives.',
    },
    {
      li: 'Coordinate parallel tasks across multiple projects and plans, prioritizes, and manages duties to meet departmental and organizational goals.',
    },
    {
      li: 'Review and participate in writing of departmental Standard Operating Procedures (SOPs).',
    },
  ],

  no4: [
    {
      li: 'Bachelor’s degree in relevant scientific discipline with at least 13+ years direct relevant experience or Master’s degree in relevant scientific discipline with 10+ years of relevant experience or PhD in relevant scientific discipline with 5+ years of industry or post-doctoral experience',
    },
    { li: 'Prior experience with cell-based assays is highly desirable.' },
    {
      li: 'Must have prior experience and demonstrated ability with execution of immunoassays (such as ELISA, ECL, ELISPOT etc), cell-based assays (experience with qPCR, and/or LCMS is a plus).',
    },
    {
      li: 'Ability to independently plan and perform experiments that support scientific activities and project goals',
    },
    {
      li: 'Ability to develop a wide range of methodologies/technologies and perform advanced data analysis',
    },
    {
      li: 'Ability to recommend alternatives as well as research new methods and techniques',
    },
    {
      li: 'Demonstrated ability to analyze and resolve problems',
    },
    {
      li: 'Ability to interpret data, draw conclusions, and recommend next steps',
    },
    {
      li: 'Ability to effectively communicate information and results in the appropriate format (i.e. lab notebooks, presentations, etc.)',
    },
    { li: 'Ability to lead internal and external functional projects' },
    { li: 'Must possess excellent oral and written communication skills.' },
    {
      li: 'Ability to present technical information to both technical and non-technical audience is required',
    },
    {
      li: 'Must be able to work collaboratively and effectively as part of a team',
    },
    { li: 'Prior industry experience preferred' },
    {
      li: 'Good computer skills and working knowledge of SoftMax or other data analysis software, MS word, Excel, Power Point are required.',
    },
    { li: 'Knowledge with gene therapy is a plus.' },
  ],
}
