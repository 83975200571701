import React from 'react'
import styles from './health.module.css'
import img1 from 'assets/health/img1.png'
import { docData } from './docData.js'

function Health() {
  return (
    <>
      <header className={styles.header_wrapper}>
        <header className={styles.header_container}>
          <div className={styles.header_layer}>
            <div className={styles.titleBox}>
              <h1 className={styles.title}>LBC Health</h1>
              <p className={styles.subTitle}>
                The blockchain-based DApp, developed by the LBC team, which also
                <br />
                applies blockchain for data self-governance implementation,
                token
                <br /> mechanism design, and encryption algorithm application.
              </p>
            </div>
          </div>
        </header>
      </header>

      <section className={styles.section_wapper}>
        <section className={styles.section_container}>
          <div className={styles.section_layer}>
            {/* 섹션1 */}
            <p className={styles.doc}>
              LBC Health uses a decentralized consensus approach to construct a
              blockchain platform to aggregate distributed health data. With the
              ultimate goal of improving overall health outcomes via genomics
              and cell research, LBC comprises a secure, decentralized, and
              visible personal health data center that removes the boundaries of
              health information between hospitals, pharmaceutical R&D
              institutions, doctors, patients, and individuals.
            </p>

            {/* 섹션이미지 */}
            <div className={styles.sec1_imgBox}>
              <img src={img1} alt="" className={styles.secImg} />
            </div>

            <span className={styles.title_doc}>Features</span>

            {/* 섹션2 */}
            <ul className={styles.ul}>
              {docData.li.map((item, index) => {
                return (
                  <li key={index} className={styles.li_wrap}>
                    <div className={styles.li}>
                      <img src={item.img} alt="" className={styles.icon} />
                      <p className={styles.p}>{item.doc}</p>
                    </div>
                  </li>
                )
              })}
            </ul>
          </div>
        </section>
      </section>
    </>
  )
}

export default Health
