import React from 'react'
import styles from './sec5.module.css'
import moment from 'moment'
import 'moment/locale/ko'

function SectionFive() {
  const nowTime = moment().format('MM/DD/YYYY')
  return (
    <section className={styles.wrapper}>
      <section className={styles.container}>
        <h1 className={styles.title}>Press Release</h1>
        <h1 className={styles.title}>{nowTime}</h1>
        <li className={styles.deskBox} data-aos="slide-up">
          <p className={styles.p}>
            <a className={styles.a} href="https://finance.yahoo.com/news/lifebank-chain-announces-upcoming-gene-200000206.html?soc_src=social-sh&soc_trk=ma">
              [Yahoo Finance] LifeBankChain Announces Upcoming Gene and Cell Collaboration
	      Platform With Disrupt Blockchain Technologies
            </a>
          </p>
        </li>
        <li className={styles.deskBox} data-aos="fade-up" data-aos-delay="200">
          <p className={styles.p}>
            The LBC committee recommends that regardless of the institutional
            structureof the entity
          </p>
        </li>
        <li className={styles.deskBox} data-aos="fade-up" data-aos-delay="400">
          <p className={styles.p}>
            The LBC committee recommends that for a proper informed
          </p>
        </li>
        <li className={styles.deskBox} data-aos="fade-up" data-aos-delay="600">
          <p className={styles.p}>
            Predictive genetic and stem cell information
          </p>
        </li>
      </section>
    </section>
  )
}

export default SectionFive
