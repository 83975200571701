import icon1 from 'assets/health/icon1.svg'
import icon2 from 'assets/health/icon2.svg'
import icon3 from 'assets/health/icon3.svg'
import icon4 from 'assets/health/icon4.svg'
import icon5 from 'assets/health/icon5.svg'
import icon6 from 'assets/health/icon6.svg'

export const docData = {
  li: [
    {
      img: icon1,
      doc: ' It ensures that health data are owned only by the individuals who upload their own health data and can never be changed without the owner’s acknowledgment.',
    },
    {
      img: icon2,
      doc: 'It establishes a trading mechanism for data exchange via tokens; these tokens are issued by the LBC management team, which was designed in accordance with Ethereum’s ERC-20 protocol. Medical and health institutions that wish to use health data must obtain the owners’ consent and pay a certain fee (ie, tokens) to buy the data. This positive mechanism can greatly encourage users to share their health data.',
    },
    {
      img: icon3,
      doc: 'The health data in LBC Health are stored on decentralized distributed nodes. All data and data transactions in the blockchain network are encrypted and traceable. LBC Health advocates blockchain as a service, which makes the use of blockchain as easy as surfing the internet. LBC Health mainly provides the following 3 product services:',
    },
    {
      img: icon4,
      doc: 'Smart contracts: It decouples decentralized smart contracts based on blockchain technology.',
    },
    {
      img: icon5,
      doc: 'Bookkeeping function: It uses distributed bookkeeping to address the lack of trust. Without a central organization, all parties have the same book to ensure an open and transparent transaction process.',
    },
    {
      img: icon6,
      doc: 'Computational processing functions: It coordinates platform resources, processes gene and stem cell data, and enables the data to be used.',
    },
  ],
}
