import Career from 'components/career/Career'
import Cell from 'components/cell/Cell'
import Footer from 'components/common/footer/Footer'
import Header from 'components/common/header/Header'
import Home from 'components/home/Home'
import Lifebank from 'components/lifebank/Lifebank'
import ScrollToToap from 'components/module/ScrollToToap'
import GeneticTherapy from 'components/genetic/GeneticTherapy'
import Overview from 'components/overview/Overview'
import React, { useEffect } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import styles from './app.module.css'
import Aos from 'aos'
import 'aos/dist/aos.css'
import Health from './components/LBChealth/Health'

function App() {
  useEffect(() => {
    Aos.init({ duration: 700, easing: 'ease-in-out' })
  }, []) //단일 애니메어션 통제

  return (
    <div className={styles.wapper}>
      <BrowserRouter>
        <ScrollToToap />

        <Header />
        <section className={styles.section}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/overview" element={<Overview />} />
            <Route path="/genetic" element={<GeneticTherapy />} />
            <Route path="/lifebank" element={<Lifebank />} />
            <Route path="/career" element={<Career />} />
            <Route path="/health" element={<Health />} />
            <Route path="/cell" element={<Cell />} />
          </Routes>
        </section>
        <Footer />
      </BrowserRouter>
    </div>
  )
}

export default App
