import React from 'react'
import SectionOne from './section1/SectionOne'
import SectionTwo from './section2/SectionTwo'
import styles from './home.module.css'
import SectionThree from './section3/SectionThree'
import SectionFour from './section4/SectionFour'
import SectionFive from './section5/SectionFive'
import SectionSix from './section6/SectionSix'

function Home() {
  return (
    <>
      <section className={styles.wrapper}>
        <SectionOne />
        <SectionTwo />
        <SectionThree />
        <SectionFour />
        <SectionFive />
        <SectionSix />
      </section>
    </>
  )
}

export default Home
