export const BoxList = {
  listL: [
    {
      cName: 'box1',
      title: 'In 2022',
      subTitle:
        'Development and integration of smart contract technology, recruitment of potential gene research laboratories and stem cell science research teams',
    },
    {
      cName: 'box2',
      title: 'In 2023',
      subTitle:
        'LBC tests and trial runs of blockchain technology; initiation of testing and demonstration for LBC Rollout Program V1.0',
      delay: 200,
    },
    {
      cName: 'box7',
      title: '2024 Q3',
      subTitle:
	'LBC Application 3 . 0 version release; providing a full- featured platform for professionals and patients; announcement of clinical development and research strategy in the fields of genetics and stem cells',
      delay: 1200,
    },
    {
      cName: 'box8',
      title: '2024 Q4',
      subTitle:
        'Deployment of offline nodes, construction of laboratories and offline product experience warehouses in Asia and Southeast Asia',
      delay: 1400,
    },
  ],

  listR: [
    {
      cName: 'box3',
      title: '2023 Q3',
      subTitle: 'LBC Application 1.1 version release; doctors and users from South Korea, Japan, Singapore, and China join the LBC platform',
      delay: 400,
    },
    {
      cName: 'box4',
      title: '2023 Q4',
      subTitle:
        'LBC Application 2.0 version release; professionals, patients, and gene and laboratory researchers from Europe and North America join LBC; online sales of genetic and stem cell products',
      delay: 600,
    },
    {
      cName: 'box5',
      title: '2024 Q1',
      subTitle:
        'Announcement of the first LBC laboratory site selection and franchising expansion plan',
      delay: 800,
    },
    {
      cName: 'box6',
      title: '2024 Q2',
      position: 5700,
      subTitle: 'hiring of the Big Four to conduct annual audits in accordance with US accounting standards and issue audit reports',
      delay: 1000,
    },
  ],

  listRL: [
    {
      cName: 'box1',
      color: '#FFDAAE',
      title: 'In 2022',
      subTitle:
        'Development and integration of smart contract technology, recruitment of potential gene research laboratories and stem cell science research teams'
    },
    {
      cName: 'box2',
      color: '#FFDAAE',
      title: 'In 2023',
      subTitle:
        'LBC tests and trial runs of blockchain technology; initiation of testing and demonstration for LBC Rollout Program V1.0',
      delay: 200,
    },
    {
      cName: 'box3',
      color: '#FFAEAE',
      title: '2023 Q3',
      subTitle: 'LBC Application 1.1 version release; doctors and users from South Korea, Japan, Singapore, and China join the LBC platform',
      delay: 400,
    },
    {
      cName: 'box4',
      color: '#FFAEAE',
      title: '2023 Q4',
      subTitle:
        'LBC Application 2.0 version release; professionals, patients, and gene and laboratory researchers from Europe and North America join LBC; online sales of genetic and stem cell products',
      delay: 600,
    },
    {
      cName: 'box5',
      color: '#FFAEAE',
      title: '2024 Q1',
      subTitle:
        'Announcement of the first LBC laboratory site selection and franchising expansion plan',
      delay: 800,
    },
    {
      cName: 'box6',
      color: '#FFAEAE',
      title: '2024 Q2',
      position: 5700,
      subTitle:
        'hiring of the Big Four to conduct annual audits in accordance with US accounting standards and issue audit reports',
      delay: 1000,
    },
    {
      cName: 'box7',
      color: '#C6B7F0',
      title: '2024 Q3',
      subTitle:
        'LBC Application 3.0 version release; providing a full- featured platform for professionals and patients; announcement of clinical development and research strategy in the fields of genetics and stem cells',
      delay: 1200,
    },
    {
      cName: 'box8',
      color: '#C6B7F0',
      title: '2024 Q4',
      subTitle:
        'Deployment of offline nodes, construction of laboratories and offline product experience warehouses in Asia and Southeast Asia',
      delay: 1400,
    },
  ],
}
