import React, { useEffect, useRef, useState } from 'react'
import styles from './header.module.css'
import logo from 'assets/logo.svg'
import { NavLink, useRoutes } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import toggle from 'assets/toggle.svg'
import toggleX from 'assets/toggleX.svg'
import ScrollLock from 'react-scrolllock'
import { navData } from './navData.js'
import GeneticTherapy from 'components/genetic/GeneticTherapy'
import Lifebank from 'components/lifebank/Lifebank'
import Overview from 'components/overview/Overview'
import Career from 'components/career/Career'
import Health from 'components/LBChealth/Health'
import Cell from 'components/cell/Cell'

function Header() {
  const mobileView = useMediaQuery({ maxWidth: 900 })
  const [active, setActive] = useState(false)
  const menuRef = useRef()
  const [navbar, setNavbar] = useState(false)
  const element = useRoutes([
    { path: '/overview', element: <Overview /> },
    { path: '/genetic', element: <GeneticTherapy /> },
    { path: '/lifebank', element: <Lifebank /> },
    { path: '/career', element: <Career /> },
    { path: '/health', element: <Health /> },
    { path: '/cell', element: <Cell /> },
  ])

  //스크롤시 헤더 고정
  const fixedHeader = () => {
    if (window.scrollY >= 100) {
      setNavbar(true)
    } else {
      setNavbar(false)
    }
  }

  window.addEventListener('scroll', fixedHeader)
  //

  //외부 클릭시 메뉴 종료
  const handleClickOutside = ({ target }) => {
    if (active && !menuRef.current?.contains(target)) {
      setActive(false)
    }
  }
  const handleClickInside = ({ target }) => {
    if (active && menuRef.current?.contains(target)) {
      setActive(false)
    }
  }

  useEffect(() => {
    window.addEventListener('click', handleClickOutside)
    window.addEventListener('click', handleClickInside)
    return () => {
      window.removeEventListener('click', handleClickOutside)
    }
  })
  //

  return (
    <header
      className={
        navbar
          ? `${`${styles.header_wrap_active} ${styles.header_wrap}`}`
          : `${styles.header_wrap} ${element && `${styles.header_element}`}`
      }
    >
      <header className={styles.container}>
        {active && <ScrollLock />}
        <NavLink to="/">
          <img src={logo} alt="" className={styles.logo} />
        </NavLink>
        <>
          {mobileView ? (
            <>
              <div
                className={styles.toggleBox}
                onClick={() => setActive(!active)}
              >
                {active ? (
                  <img src={toggleX} alt="" className={styles.toggle} />
                ) : (
                  <img src={toggle} alt="" className={styles.toggle} />
                )}
              </div>

              <div
                className={
                  active
                    ? `${styles.layer_wrap} ${styles.layer_wrap_active}`
                    : `${styles.layer_wrap}`
                }
              >
                <div
                  className={
                    active
                      ? `${styles.nav_wrapper} ${styles.nav_wrapper_active}`
                      : `${styles.nav_wrapper}`
                  }
                  ref={menuRef}
                >
                  <div className={styles.nav_container}>
                    {navData.mNav.map((item, index) => {
                      return (
                        <NavLink
                          to={item.link}
                          className={styles.navName}
                          key={index}
                        >
                          {item.name}
                        </NavLink>
                      )
                    })}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className={styles.navBox}>
              <div className={`${styles.navName} ${styles.hover_dropBox}`}>
                OurServices
                <ul className={styles.drop_wrap}>
                  <div className={styles.drop_container}>
                    {navData.hoverNav.map((item, index) => {
                      return (
                        <NavLink
                          key={index}
                          to={item.link}
                          className={styles.dropNav}
                        >
                          {item.dropName}
                        </NavLink>
                      )
                    })}
                  </div>
                </ul>
              </div>
              <div className={`${styles.navName} ${styles.hover_dropBox}`}>
                lifebank
                <ul className={styles.drop_wrap}>
                  <div className={styles.drop_container}>
                    {navData.hoverNav2.map((item, index) => {
                      return (
                        <NavLink
                          key={index}
                          to={item.link}
                          className={styles.dropNav}
                        >
                          {item.dropName}
                        </NavLink>
                      )
                    })}
                  </div>
                </ul>
              </div>
              <NavLink to="/career" className={styles.navName}>
                Career
              </NavLink>
            </div>
          )}
        </>
      </header>
    </header>
  )
}

export default Header
