import React from 'react'
import styles from './career.module.css'
import { docData } from './docData'

function Career() {
  return (
    <>
      <header className={styles.header_wrapper}>
        <header className={styles.header_container}>
          <div className={styles.header_layer}>
            <div className={styles.titleBox}>
              <h1 className={styles.title}>Career</h1>
              <p className={styles.subTitle}>
                Send your CV to contactus@lifebankchain.io
              </p>
            </div>
          </div>
        </header>
      </header>

      <section className={styles.section_wapper}>
        <section className={styles.section_container}>
          <div className={styles.section_layer}>
            {/* 섹션1 */}
            <h1 className={styles.title_doc}>Culture and Values</h1> <br />
            <br />
            <p className={styles.doc}>
              <b>At LBC</b>, we believe that what we do matters – to patients,
              to their families and to their communities. We set a high bar for
              having a culture focused on patients, driven by results, and one
              that fosters collaboration. All of this comes to life from the
              trust we place in each other and in our commitment to develop gene
              and stem cell therapy products for patients with limited
              therapeutic options. As we strive to be nimble and efficient, we
              recognize and reward the perseverance of our people dedicated to
              finding innovative ways to advance our mission. We hold ourselves
              and our teammates accountable to serve our patients in need.
            </p>
            <span className={styles.title2_doc}>Join our team!</span>
            <p className={styles.doc}>
              LBC is looking for passionate people who are motivated to make an
              impact in patients’ lives. Among the many perks of working at LBC,
              we offer comprehensive and competitive benefits for you and your
              family.
            </p>
            {/* 섹션2 */}
            <h1 className={`${styles.title_doc} ${styles.title_doc2}`}>
              IT Business Systems Analyst
            </h1>
            <span className={styles.title2_doc}>Job Details</span>
            <p className={styles.doc}>
              As the IT Business Systems Analyst, you will have the opportunity
              to collaborate with stakeholders to elicit and analyze
              requirements, determine appropriate solutions, evaluate and select
              suitable products, manage implementation and delivery, and provide
              on-going support of systems.
            </p>
            <span className={`${styles.title2_doc} ${styles.title3_doc}`}>
              What you’ll be doing
            </span>
            <ul className={styles.ul}>
              {docData.no1.map((item, index) => {
                return (
                  <li key={index} className={styles.li}>
                    <div className={styles.listBox} />
                    <p className={styles.li_doc}>{item.li}</p>
                  </li>
                )
              })}
              <p className={styles.doc2}>
                We set our employees up for success. To be successful in this
                role and help us achieve our goals, we are looking for someone
                with the following skills and qualifications:
              </p>
              {docData.no2.map((item, index) => {
                return (
                  <li key={index} className={styles.li}>
                    <div className={styles.listBox} />
                    <p className={styles.li_doc}>{item.li}</p>
                  </li>
                )
              })}
            </ul>
            {/* 섹션3 */}
            <h1 className={`${styles.title_doc} ${styles.title_doc2}`}>
              Scientist/Senior Scientist, Bioanalytical Sciences
            </h1>
            <span className={styles.title2_doc}>Job Details</span>
            <p className={styles.doc}>
              You will develop and validate bioanalytical methods to support the
              LBC gene and stem cell therapy products pipeline. You will use the
              current available technologies and will explore novel
              techniques/technologies as appropriate. Additionally, you may
              oversee Contract Research Organizations to ensure that all
              applicable bioanalytical test methods are appropriate for the
              stage of non-clinical and/or clinical development and sufficiently
              qualified or validated to support ongoing and future studies in
              alignment with regulatory expectations and industry standards.
            </p>
            <span className={`${styles.title2_doc} ${styles.title3_doc}`}>
              What you’ll be doing
            </span>
            <ul className={styles.ul}>
              {docData.no3.map((item, index) => {
                return (
                  <li key={index} className={styles.li}>
                    <div className={styles.listBox} />
                    <p className={styles.li_doc}>{item.li}</p>
                  </li>
                )
              })}

              <p className={styles.doc2}>
                We set our employees up for success. To be successful in this
                role and help us achieve our goals, we are looking for someone
                with the following skills and qualifications:
              </p>

              {docData.no4.map((item, index) => {
                return (
                  <li key={index} className={styles.li}>
                    <div className={styles.listBox} />
                    <p className={styles.li_doc}>{item.li}</p>
                  </li>
                )
              })}
            </ul>
            <p className={styles.finish}>Send your CV to contactus@lifebankchain.io</p>
          </div>
        </section>
      </section>
    </>
  )
}

export default Career
