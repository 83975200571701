import React from 'react'
import styles from './sec2.module.css'
import icon from 'assets/homeSvg/section2-icon.svg'
import svg from 'assets/homeSvg/section2.svg'

function SectionTwo({ isScrolling }) {
  return (
    <>
      <section id="section2" className={styles.wrapper}>
        <section className={styles.container}>
          <h1 className={styles.title} data-aos="zoom-in-up">
            Discover
          </h1>
          <p
            className={styles.subTitle}
            data-aos="zoom-in-up"
            data-aos-delay="100"
          >
            Lifebank Chain Focuses on the research&#44; development of
            genetics&#44; cytology&#44;
            <br />
            expands the innovative applications of genetics and cytology in
            <br />
            clinical treatment through cutting-edge technology&#46;
          </p>
          <div className={styles.contentBox}>
            <div
              className={styles.svgBox}
              data-aos="zoom-in-up"
              data-aos-delay="200"
            >
              <img src={svg} alt="" className={styles.svg} />
            </div>

            <ul className={styles.contents}>
              <li
                className={styles.content}
                data-aos="zoom-in-up"
                data-aos-delay="200"
              >
                <h2 className={styles.cTitle}>
                  <img src={icon} alt="" className={styles.icon} />
                  First
                </h2>
                <p className={styles.cSubTitle}>
                  You solve{' '}
                  <b className={styles.b}>
                    gene and cell
                    <br />
                    data security
                  </b>{' '}
                  issues&#46;
                </p>
              </li>

              <li
                className={`${styles.content} ${styles.contentTwo}`}
                data-aos="zoom-in-up"
                data-aos-delay="300"
              >
                <h2 className={styles.cTitle}>
                  <img src={icon} alt="" className={styles.icon} />
                  Second
                </h2>
                <p className={styles.cSubTitle}>
                  You solve{' '}
                  <b className={styles.b}>
                    data access
                    <br /> and data mining
                    <br /> performance
                  </b>{' '}
                  issues&#46;
                </p>
              </li>

              <li
                className={styles.content}
                data-aos="zoom-in-up"
                data-aos-delay="400"
              >
                <h2 className={styles.cTitle}>
                  <img src={icon} alt="" className={styles.icon} />
                  Third
                </h2>
                <p className={styles.cSubTitle}>
                  You solve{' '}
                  <b className={styles.b}>
                    gene and
                    <br /> cell data rational use
                  </b>{' '}
                  <br />
                  issues.
                </p>
              </li>
            </ul>
          </div>
        </section>
      </section>
    </>
  )
}

export default SectionTwo
