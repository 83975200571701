export const navData = {
  hoverNav: [
    {
      dropName: 'Overview', //
      link: '/overview',
    },
    {
      dropName: 'Cell Therapy', //
      link: '/cell',
    },
    {
      dropName: 'Genetic Therapy', //
      link: '/genetic',
    },
  ],

  hoverNav2: [
    {
      dropName: 'Lifebank', //
      link: '/lifebank',
    },
    {
      dropName: 'LBC health', //
      link: '/health',
    },
  ],

  mNav: [
    {
      name: 'Overview',
      link: '/overview',
    },
    {
      name: 'Cell Therapy',
      link: '/cell',
    },
    {
      name: 'Genetic Therapy',
      link: '/genetic',
    },
    {
      name: 'Lifebank',
      link: '/lifebank',
    },
    {
      name: 'LBC Health',
      link: '/health',
    },
    {
      name: 'Career',
      link: '/career',
    },
  ],
}
