import img1 from 'assets/cell/sec2_img1.png'
import img2 from 'assets/cell/sec2_img2.png'
import img3 from 'assets/cell/sec2_img3.png'
import img4 from 'assets/cell/sec2_img4.png'

export const docData = {
  sec2: [
    {
      img: img1,
      doc: 'Replace and regenerate damaged or diseased bone marrow',
    },
    {
      img: img2,
      doc: 'Correct genetic defects (sibling/allogeneic transplantation)',
    },
    {
      img: img3,
      doc: 'Potential for cellular and regenerative therapy',
    },
    {
      img: img4,
      doc: 'Treatment for blood cancers',
    },
  ],

  sec6: [
    {
      doc: 'grow new cells in a laboratory to replace damaged organs or tissues',
    },
    {
      doc: 'correct parts of organs that don’t work properly',
    },
    {
      doc: 'research causes of genetic defects in cells',
    },
    {
      doc: 'research how diseases occur or why certain cells develop into cancer cells',
    },
    {
      doc: 'test new drugs for safety and effectiveness',
    },
  ],

  sec7: [
    {
      doc: 'Axicabtagene ciloleucel (Yescarta®): a CD19-targeting CAR T cell immunotherapy; approved for subsets of patients with lymphoma',
    },
    {
      doc: 'Brexucabtagene autoleucel (Tecartus™): a CD19-targeting CAR T cell immunotherapy; approved for subsets of patients with lymphoma',
    },
    {
      doc: 'Lisocabtagene maraleucel (Breyanzi®): a CD19-targeting CAR T Cell immunotherapy; approved for subsets of patients with lymphoma',
    },
    {
      doc: 'Tisagenlecleucel (Kyrmriah®): a CD19-targeting CAR T cell immunotherapy; approved for subsets of patients with leukemia and lymphoma',
    },
  ],

  sideLi: [
    { id: '#sec1', title: 'Saving Cord Blood' },
    {
      id: '#sec2',
      title: 'Cord Blood stem cell transplants are uesd in the following ways',
    },
    { id: '#sec3', title: 'Benefits of Saving Cord Blood' },
    { id: '#sec4', title: 'Cellular-based therapy for regenerative medicine' },
    { id: '#sec5', title: 'Biorecover Repair Concentrate' },
    { id: '#sec6', title: 'Anti-Wrinkle Injecti' },
    { id: '#sec7', title: 'Stem cell and immune cell advanced therapy' },
    { id: '#sec8', title: 'Immune cell therapy' },
  ],
}
