import React from 'react'
import styles from './sec6.module.css'
import img1 from 'assets/homeSvg/sec6-img1.png'
import img2 from 'assets/homeSvg/sec6-img2.png'
import img3 from 'assets/homeSvg/sec6-img3.png'
import img4 from 'assets/homeSvg/sec6-img4.png'
import { Link } from 'react-router-dom'

function SectionSix() {
  return (
    <section className={styles.wrapper}>
      <section className={styles.container}>
        <h1 className={styles.title} data-aos="zoom-up">
          Our Research and development partners
        </h1>
        <p className={styles.subTitle} data-aos="zoom-up" data-aos-delay="200">
          Lifebank Chain is constantly looking for partners and research
          <br />
          collaborators&#46; Contacts us for more information&#46;
        </p>

        <div
          className={styles.imgBox}
          data-aos="zoom-in-up"
          data-aos-delay="300"
        >
          <img src={img1} alt="" className={styles.img} />
          <img src={img2} alt="" className={styles.img} />
          <img src={img3} alt="" className={styles.img} />
          <img src={img4} alt="" className={styles.img} />
        </div>
        <Link to="#" className={styles.btn1}>
          Contact us
        </Link>
      </section>
    </section>
  )
}

export default SectionSix
