import React from 'react'
import { useMediaQuery } from 'react-responsive'
import styles from './lifebank.module.css'
import img1 from 'assets/lifebank/img1.png'
import img2 from 'assets/lifebank/img2.png'

function Lifebank() {
  const mobile = useMediaQuery({ maxWidth: 580 })
  return (
    <>
      <header className={styles.header_wrapper}>
        <header className={styles.header_container}>
          <div className={styles.header_layer}>
            <div className={styles.titleBox}>
              {mobile && <h1 className={styles.title}>Lifebank</h1>}
            </div>
          </div>
        </header>
      </header>

      <section className={styles.section_wapper}>
        <section className={styles.section_container}>
          <div className={styles.section_layer}>
            <h1 className={styles.title_doc}>Lifebank</h1>
            <br />
            <p className={styles.doc}>
              {/* 섹션1 */}
              The LBC team plans to integrate and attract various gene and cell
              technologies and scientific research institutions to participate
              in genetic and cell data analysis and mining, while maximizing the
              value of the data, and providing comprehensive, customized health
              management and life guidance for gene and cell contributors.
            </p>

            <div className={styles.sec1_imgBox}>
              <img src={img1} alt="" className={styles.secImg} />
            </div>

            <p className={styles.doc}>
              The project will also focus efforts and resources on developing
              application scenarios for analyzing and using the genetic and cell
              data collected, enabling the data to be used by parties for the
              open advancement of life sciences for humanity, which aims to
              leverage blockchain technology to store and manage biomedical data
              and to compensate individuals for contributing their genomic and
              cell data for biomedical research.
            </p>

            {/* 섹션2 */}

            <div className={styles.sec2Box}>
              <div className={styles.sec2_wrap}>
                <h2 className={`${styles.title_doc} ${styles.title_doc2}`}>
                  First
                </h2>
                <p className={`${styles.doc}`}>
                  Our mission is to create a global human genome and cell data
                  hub by enabling decentralized data storage. LBC will enable
                  human genome and cell data to be publicly accessible and ready
                  for sharing while protecting the security and privacy of the
                  data owner.
                </p>
              </div>
              <div className={styles.sec2_imgBox}>
                <img src={img2} alt="" className={styles.secImg} />
              </div>
            </div>

            {/* 섹션3 */}
            <h2 className={`${styles.title_doc} ${styles.title_doc_sec3}`}>
              Second
            </h2>
            <p className={styles.doc}>
              Our efforts will break the silos of industry information and
              integrate the human genome and cell data on a blockchain by
              building a global biomedical data hub and providing a transparent
              service platform for various disease research and medical
              applications.
            </p>
          </div>
        </section>
      </section>
    </>
  )
}

export default Lifebank
