import React from 'react'
import styles from './sec4.module.css'
import xIcon from 'assets/xIcon.svg'
import linkIcon from 'assets/footer/link.svg'

/*
function Modal({ img, name, desc, hide, linkedin }) {
  return (
    <div className={styles.layer_wrapper}>
      <div className={styles.layer_container}>
        <div
          className={styles.modal_wrapper}
          data-aos="fade-up"
          data-aos-duration="600"
        >
          <button className={styles.xBox} onClick={hide}>
            <img src={xIcon} alt="" className={styles.xIcon} />
          </button>
          <div className={styles.modal_container}>
            <div className={styles.modalTitle}>
              <img src={img} alt="" className={styles.modalImg} />
              <p className={styles.modalName}>
	         <a href={linkedin} target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>{name}</a>
	      </p>
            </div>
            <p className={styles.desc}>{desc}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
*/

function Modal({ img, name, desc, hide, linkedin }) {
  return (
    <div className={styles.layer_wrapper}>
      <div className={styles.layer_container}>
        <div
          className={styles.modal_wrapper}
          data-aos="fade-up"
          data-aos-duration="600"
        >
          <button className={styles.xBox} onClick={hide}>
            <img src={xIcon} alt="" className={styles.xIcon} />
          </button>
          <div className={styles.modal_container}>
            <div className={styles.modalTitle}>
              <img src={img} alt="" className={styles.modalImg} />
              <p className={styles.modalName}>
                {name}
                <a
                  href={linkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ marginLeft: '8px' }}
                >
                  <img
                    src={linkIcon}
                    alt="LinkedIn"
                    className={styles.linkIcon}
	            style={{ width: '18px', height: '18px' }}
                  />
                </a>
              </p>
            </div>
            <p className={styles.desc}>{desc}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal
