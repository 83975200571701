import React from 'react'
import styles from './sec3.module.css'
import bar from 'assets/homeSvg/section3bar.svg'
import { BoxList } from './BoxList'
import { useMediaQuery } from 'react-responsive'

function SectionThree() {
  const mobile = useMediaQuery({maxWidth: 880})

  return (
    <section className={styles.wrapper}>
      <section className={styles.container}>
        <h1 className={styles.title}>LBC Milestones</h1>

        <div className={styles.boxLayer}>
            <div className={styles.boxLayer_wrap}>

          {mobile || <div className={styles.barBox}>
            <img src={bar} alt="" className={styles.bar} />
          </div>}

        {mobile ? <div className={styles.listRL}>
          {BoxList.listRL.map((item, index) => {
              return (
                <li key={index} className={styles.[item.cName]} data-aos="fade-up"
                data-aos-delay={item.delay}>
                  <h2 className={styles.bTitle} style={{color:item.color}}>{item.title}</h2>
                  <p className={styles.bSubTitle}>{item.subTitle}</p>
                </li>
              )
            })}
        </div> 
        : 
        <>
           <div className={styles.listL} >
            {BoxList.listL.map((item, index) => {
              return (
                <li key={index} className={styles.[item.cName]}  
                data-aos="fade-right"
                data-aos-delay={item.delay}>
                  <h2 className={styles.bTitle}>{item.title}</h2>
                  <p className={styles.bSubTitle}>{item.subTitle}</p>
                </li>
              )
            })}
          </div>

          <div className={styles.listR}>
            {BoxList.listR.map((item, index) => {
              return (
                <li key={index} className={styles.[item.cName]}
                data-aos="fade-left"
                data-aos-delay={item.delay}>
                  <h2 className={styles.bTitle}>{item.title}</h2>
                  <p className={styles.bSubTitle}>{item.subTitle}</p>
                </li>
              )
            })}
          </div>
        </> }
       
          </div>
        </div>
      </section>
    </section>
  )
}

export default SectionThree
